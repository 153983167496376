<template>
  <b-modal
    id="activate-tink-connection-modal"
    centered
    ref="ActivateTinkConnectionModal"
    modal-class="spirecta-modal"
    @show="onShow"
    @hide="onHide"
  >
    <template slot="modal-title">
      {{ $t(translationPath + 'title', { name: this.connection ? this.connection.name : '' }) }}
    </template>
    <template v-if="!errorMessage && clearedConnection !== null && connection !== null">
      <b-embed v-if="this.clearedConnection !== null" id="bank_iframe" ref="TinkIframe" type="iframe" :src="tinkLink" class="mt-5 tink-iframe" allow-scripts allow-same-origin allow-popups allow-forms allow-popups-to-escape-sandbox allow-top-navigation></b-embed>
    </template>
    <template v-else-if="errorMessage">
      <b-alert variant="danger">errorMessage</b-alert>
    </template>
    <template v-else>
      <loader />
    </template>
    <template slot="modal-footer" class="text-center">
      <b-button variant="primary" @click="onClose">{{ $t('common.close') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/common/Loader'
import Tink from '@/mixins/Tink.js'

export default {
  name: 'ActivateTinkConnectionModal',
  components: { Loader },
  mixins: [Tink],
  props: {
    connection: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      translationPath: 'settings.your_data.tink.activate_connection_modal.',
      clearedConnection: null,
      errorMessage: ''
    }
  },
  computed: {
    ...mapState('user', ['currentUser']),
    locale () {
      let returnvalue = 'en_US'
      switch (this.currentUser.default_lang) {
        case 'sv':
          returnvalue = 'sv_SE'
          break
        case 'da':
          returnvalue = 'da_DK'
          break
        case 'en':
        default:
          returnvalue = 'en_US'
      }

      return returnvalue
    },
    tinkLink () {
      if (this.clearedConnection === null || this.connection === null) {
        return ''
      }

      let link = `https://link.tink.com/1.0/credentials/add?client_id=${this.currentUser.tink_client_id}&scope=accounts:read,investments:read,transactions:read,user:read,credentials:read,identity:read&market=SE&locale=${this.locale}&iframe=true&authorization_code=${this.clearedConnection.tink_credentials_authorization_code}&redirect_uri=${document.location.origin}/tink-callback`

      link += '&input_provider=' + this.connection.tink_provider.provider_name

      return link
    }
  },
  methods: {
    onShow () {
      this.$emit('show')
    },
    onHide () {
      this.$emit('hide')
    },
    show () {
      this.$refs.ActivateTinkConnectionModal.show()
      process.nextTick(() => {
        this.reinitializeConnection(this.connection.id)
          .then(clearedConnection => {
            this.clearedConnection = clearedConnection
          })
      })
      this.$emit('show')
    },
    hide () {
      this.clearedConnection = null
      this.$refs.ActivateTinkConnectionModal.hide()
      this.$emit('hide')
    },
    onClose () {
      this.clearedConnection = null
      this.errorMessage = ''
      this.$refs.ActivateTinkConnectionModal.hide()
      this.$emit('close')
    },
    receiveIframeMessage (event) {
      if (event.origin !== 'https://link.tink.com') {
        return false
      }

      const { type, error, additionalInformation } = JSON.parse(event.data)
      if (type !== 'error' && type !== 'none') {
        return false
      }

      if (error) {
        this.errorMessage = error.message
        this.writeLog(
          'FAILED',
          'Activate connection modal: ' + error.message,
          this.tinkLink,
          this.connection.tink_provider.provider_name,
          this.connection.tink_provider.id,
          this.connection.id
        )
        this.$bvToast.toast(this.$t(this.translationPath + 'activation_fail', { message: error.message }), {
          title: this.$t(this.translationPath + 'activation_title'),
          variant: 'danger',
          solid: true,
          'auto-hide-delay': 3000
        })
        return false
      }

      const credentialsId = Object.prototype.hasOwnProperty.call(additionalInformation, 'credentialsId') ? additionalInformation.credentialsId : null
      if (type === 'none' && credentialsId) {
        this.writeLog(
          'OK',
          'Activate connection modal: ' + 'Receive credentials id: ' + credentialsId,
          this.tinkLink,
          this.connection.tink_provider.provider_name,
          this.connection.tink_provider.id,
          this.connection.id
        )
        this.saveCredentials(credentialsId, this.connection.id)
          .then(response => {
            this.writeLog(
              'OK',
              'Activate connection modal: ' + 'Credentials ' + credentialsId + ' are saved to database.',
              this.tinkLink,
              this.connection.tink_provider.provider_name,
              this.connection.tink_provider.id,
              this.connection.id
            )
            this.$emit('activated', { connection: response.connection })
            this.$bvToast.toast(this.$t(this.translationPath + 'alert_enable_authenticated_success'), {
              title: this.$t(this.translationPath + 'alert_enable_title'),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            })
          })
          .catch(err => {
            this.errorMessage = this.$t(this.translationPath + 'alert_enable_fail')
            console.error(err)
            this.writeLog(
              'FAILED',
              'Activate connection modal: ' + 'Credentials ' + credentialsId + ' are not saved to database.',
              this.tinkLink,
              this.connection.tink_provider.provider_name,
              this.connection.tink_provider.id,
              this.connection.id
            )
            this.$bvToast.toast(this.$t(this.translationPath + 'alert_enable_fail'), {
              title: this.$t(this.translationPath + 'alert_enable_title'),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            })
          })
        return true
      }

      return false
    }
  }
}
</script>

<style lang="scss">
#activate-tink-connection-modal {
  .embed-responsive {
    height: 500px;
  }
  iframe {
    height: 92%;
  }
}
</style>
