<template>
  <b-modal id="deleteChart" centered ref="deleteChart" @hide="hide" modal-class="spirecta-modal">
    <template slot="modal-title">
      {{ $t(translationPath + 'delete.delete_data_modal.title') }}
    </template>
    <template v-if="validPurposes.indexOf(purpose) !== -1">
      <b-col cols="12" class="px-0">
        <p>{{ $t(translationPath + 'delete.delete_data_modal.content_' + purpose)}}</p>
      </b-col>
      <b-col cols="12" class="px-0">
        <b-form-group
          :state="$v.date.$dirty ? !$v.date.$error : null"
          :invalid-feedback="invalidDateFeedback"
          :label="this.$t(translationPath + 'delete.delete_data_modal.confirm_date_label', { today: today })"
          label-for="date-input"
        >
          <b-form-input
            id="date-input"
            v-model="date"
            :placeholder="this.$t(translationPath + 'delete.delete_data_modal.confirm_date_placeholder')"
            class="mt-3"
            :state="$v.date.$dirty ? !$v.date.$error : null"
            @input="$v.date.$touch()"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          v-if="purpose === 'delete-coa'"
          :label="this.$t(translationPath + 'delete.delete_data_modal.reset_to_default_label')"
          label-for="reset-to-default"
        >
          <b-form-select id="reset-to-default" v-model="resetAccounts" :options="resetAccountsOptions"></b-form-select>
        </b-form-group>
      </b-col>
    </template>
    <template v-else>
      <b-alert :show="true" variant="danger">{{ $t(translationPath + 'delete.delete_data_modal.invalid_purpose') }}</b-alert>
    </template>
    <template slot="modal-footer" class="text-center">
      <b-button variant="outline" @click="onCancelled">{{ $t('common.cancel') }}</b-button>
      <b-button variant="danger" @click="onConfirmed" :disabled="disabledButton">
        <template v-if="purpose === 'delete-coa'">
          {{ $t(translationPath + 'delete.delete_data_modal.action_delete') }}
        </template>
        <template v-else-if="purpose === 'delete-transactions'">
          {{ $t(translationPath + 'delete.delete_data_modal.action_delete_transactions') }}
        </template>
        <template v-else>
          {{ $t('common.delete') }}
        </template>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { helpers, required } from 'vuelidate/lib/validators'
import moment from 'moment'

const dateFormatVerify = helpers.regex('dateFormatVerify', /^([1-2][0-9][0-9][0-9])-(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[0-1])+$/)
const validDate = getter => function () {
  return this.isValidDate
}

export default {
  name: 'DeleteCoaModal',
  props: {
    purpose: {
      type: String,
      default: 'delete-coa'
    }
  },
  data () {
    return {
      translationPath: 'settings.your_data.',
      resetAccounts: 1,
      date: null,
      sValidDate: true,
      validPurposes: ['delete-coa', 'delete-transactions']
    }
  },
  validations: {
    date: {
      required,
      dateFormatVerify,
      validDate: validDate()
    },
    form: ['date']
  },
  computed: {
    resetAccountsOptions () {
      const opts = [
        { value: '0', text: this.$t('common.no') },
        { value: '1', text: this.$t('common.yes') }
      ]
      return opts
    },
    invalidDateFeedback () {
      if (this.$v.date.required === false) { return this.$i18n.t(this.translationPath + 'delete.delete_data_modal.errors.required') }
      if (this.$v.date.dateFormatVerify === false) { return this.$i18n.t(this.translationPath + 'delete.delete_data_modal.errors.invalid_format') }
      if (this.$v.date.validDate === false) { return this.$i18n.t(this.translationPath + 'delete.delete_data_modal.errors.invalid_date') }
      return ''
    },
    disabledButton () {
      return this.date ? (this.$v.date.$dirty ? this.$v.date.$error : false) : true
    },
    today () {
      return this.$moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    show () {
      this.$refs.deleteChart.show()
    },
    hide () {
      this.$refs.deleteChart.hide()
      this.isValidDate = true
      this.date = null
      this.$v.$reset()
    },
    onConfirmed () {
      this.$emit('confirmed', { resetAccounts: this.resetAccounts })
      this.hide()
    },
    onCancelled () {
      this.$emit('cancelled')
      this.hide()
    }
  },
  watch: {
    date (newVal) {
      const currentDate = moment().format('YYYY-MM-DD').toString()
      if (newVal) {
        this.isValidDate = (this.$v.date.dateFormatVerify) ? newVal === currentDate : true
      }
    }
  }
}
</script>
