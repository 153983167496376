<template>
  <b-container class="settings-tab misc bg-light page-wrapper">

    <b-row class="main-content-wrapper pb-0" no-gutters>
      <b-col>
        <settings-submenu></settings-submenu>
      </b-col>
    </b-row>

    <b-row class="main-content-wrapper pt-0" no-gutters>

      <b-col sm="12" md="5" lg="3" class="detail-text text-left py-4 pr-5">
        <h3 class="text-dark">{{ $t(translationPath + 'title') }}</h3>
        <p class="text-gray">{{ $t(translationPath + 'description') }}</p>
      </b-col>

      <b-col class="px-0">
        <b-tabs card class="transparent-tabs" v-model="activeTab">

          <!-- Stats on your data -->
          <b-tab :title="$t(translationPath + 'stats.title')" @click="onTabClick('statistics')" class="px-4 py-3">
            <h3 class="pb-2">{{ $t(translationPath + 'stats.heading') }}</h3>
            <template v-if="chartAccountStatus !== null">
              <b>{{ $t(translationPath + 'stats.transactions') }}</b>
              <ul>
                <li v-for="(value, name) in chartAccountStatus.transactions[0]" v-bind:key="name">
                  <span>{{ $t(translationPath + 'stats.' + name) }}:</span> {{ value }}
                </li>
              </ul>
              <b>{{ $t(translationPath + 'stats.accounts') }}</b>
              <ul>
                <li v-for="(value, name) in chartAccountStatus.accounts" v-bind:key="name">
                  <span>{{ $t(translationPath + 'stats.number_of_accounts_' + value['type']) }}:</span> {{ value['number_of_accounts'] }} st
                </li>
              </ul>
            </template>
          </b-tab>
          <!-- End stats on your data -->

          <!-- Export -->
          <b-tab :title="$t(translationPath + 'export.title')" @click="onTabClick('export')" class="px-4 py-3">
            <your-data-export></your-data-export>
          </b-tab>
          <!-- END Export -->

          <b-tab :title="$t(translationPath + 'balansbladet_import.title')" @click="onTabClick('import')" class="px-4 py-3">
            <h4 class="">{{ $t(translationPath + 'balansbladet_import.title')}}</h4>
            <p class="col-lg-8 px-0">{{ $t(translationPath + 'balansbladet_import.description')}}</p>
            <ul>
              <li><b-link to="/settings/balansbladet-import/" class="text-blue">{{ $t(translationPath + 'balansbladet_import.open_guide') }} →</b-link></li>
            </ul>
          </b-tab>

          <!-- Delete -->
          <b-tab :title="$t(translationPath + 'delete.title')" @click="onTabClick('delete')" class="px-4 py-3">
            <b-row no-gutters>
              <b-col lg="8" class="px-0">
                <h4 class="">{{ $t(translationPath + 'delete.delete_data_label')}}</h4>
                <p class="px-0">{{ $t(translationPath + 'delete.delete_data_description')}}</p>
                <b-alert class="mt-2" variant="danger" :show="coasLength > 1">
                  <i18n :path="translationPath + 'delete.alert_to_big_coas_to_delete'" tag="p">
                    <b-link slot="link" to="/settings/chart-of-account/manage">{{ $t(translationPath + 'delete.manage_coas_link') }}</b-link>
                  </i18n>
                 </b-alert>
                 <b-alert class="mt-2" variant="danger" :show="coasLength === 0">
                  <i18n :path="translationPath + 'delete.alert_no_coa'" tag="p">
                    <b-link slot="link" to="/settings/chart-of-account/manage">{{ $t(translationPath + 'delete.manage_coas_link') }}</b-link>
                  </i18n>
                 </b-alert>
                <b-button
                  v-if="coasLength == 1"
                  class="btn-sm mr-2 mb-2"
                  variant="danger"
                  :disabled="coasLength !== 1 || isInProcess"
                  @click.prevent="onDeleteTransactionsClick"
                >
                  {{ $t(translationPath + 'delete.delete_transactions_button') }}
                </b-button>

                <p class="px-0 mt-3">{{ $t(translationPath + 'delete.delete_and_reset_all')}}</p>
                <b-button
                  v-if="coasLength == 1"
                  class="btn-sm mr-2 mb-2"
                  variant="danger"
                  :disabled="coasLength !== 1 || isInProcess"
                  @click.prevent="onTruncateCoaClick"
                >
                  {{ $t(translationPath + 'delete.delete_data_button') }}
                </b-button>
              </b-col>
            </b-row>
            <div v-if="coasLength === -1" class="my-5"><loader /></div>
            <delete-coa-modal
              ref="DeleteCoaModal"
              purpose="delete-coa"
              @confirmed="deleteCOA"
            />
            <delete-coa-modal
              ref="DeleteTransactionsModal"
              purpose="delete-transactions"
              @confirmed="deleteTransactions"
            />
          </b-tab>
          <!-- End delete -->

          <b-tab :title="$t(translationPath + 'tink.title')" @click="onTabClick('tink')" class="px-4 py-3">
            <your-data-tink></your-data-tink>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex'
import SettingsSubmenu from './../SettingsSubmenu'
import axios from 'axios'
import Loader from '@/components/common/Loader'
import TitleMixins from '@/mixins/title'
import YourDataExport from './YourDataExport'
import YourDataTink from './YourDataTink'
import DeleteCoaModal from '@/components/modals/DeleteCoaModal'

export default {
  name: 'ChartAccount',
  mixins: [TitleMixins],
  components: { SettingsSubmenu, YourDataExport, YourDataTink, DeleteCoaModal, Loader },
  data () {
    return {
      translationPath: 'settings.your_data.',
      activeTab: 0,
      chartAccountStatus: null,
      coasLength: -1,
      tabs: {
        statistics: 0,
        export: 1,
        import: 2,
        delete: 3,
        tink: 4
      },
      isInProcess: false
    }
  },
  created () {
    if (Object.prototype.hasOwnProperty.call(this.tabs, this.$route.params.tabname)) {
      this.activeTab = this.tabs[this.$route.params.tabname]
    } else {
      this.onTabClick('statistics')
    }
    this.loadCoasLength()
  },
  computed: {
    titleMeta () {
      return this.$t('settings.submenu.your-data')
    }
  },
  methods: {
    ...mapActions('user', ['loadCurrentCOA']),
    async loadCoasLength () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts`)
          .then(response => {
            this.coasLength = response.data.data.length
            resolve(this.coas)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    deleteCOA (eventData) {
      if (this.isInProcess) {
        return false
      }
      this.isInProcess = true

      const deleteData = { reset_accounts: eventData.resetAccounts }
      axios.delete(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/destroy-all`, { data: deleteData })
        .then(response => response.data.data)
        .then(responseData => {
          this.loadCurrentCOA()
          this.$bvToast.toast(this.$t(this.translationPath + 'delete.toast.delete_coa_success'), {
            title: this.$t(this.translationPath + 'delete.toast.title'),
            autoHideDelay: 3000,
            variant: 'success'
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'delete.toast.delete_coa_fail'), {
            title: this.$t(this.translationPath + 'delete.toast.title'),
            autoHideDelay: 3000,
            variant: 'danger'
          })
        })
        .finally(() => {
          this.isInProcess = false
        })
    },
    deleteTransactions (eventData) {
      if (this.isInProcess) {
        return false
      }
      this.isInProcess = true

      axios.delete(`${process.env.VUE_APP_ROOT_API}/transactions/destroy-all`)
        .then(response => response.data.data)
        .then(responseData => {
          this.loadCurrentCOA()
          this.$bvToast.toast(this.$t(this.translationPath + 'delete.toast.delete_transactions_success'), {
            title: 'Chart of account',
            autoHideDelay: 3000,
            variant: 'success'
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'delete.toast.delete_transactions_fail'), {
            title: 'Chart of account',
            autoHideDelay: 3000,
            variant: 'danger'
          })
        })
        .finally(() => {
          this.isInProcess = false
        })
    },
    fetchChartofaccountStatus () {
      axios.get(`${process.env.VUE_APP_ROOT_API}/chart-of-accounts/stats`)
        .then(response => {
          this.chartAccountStatus = response.data.data
        }).catch((error) => {
          console.error(error)
        })
    },
    onTabClick (tab) {
      if (this.$route.params.tabname !== tab) {
        this.$router.push('/settings/your-data/' + tab)
      }
    },
    onTruncateCoaClick () {
      this.$refs.DeleteCoaModal.show()
    },
    onDeleteTransactionsClick () {
      this.$refs.DeleteTransactionsModal.show()
    }
  },
  mounted () {
    this.fetchChartofaccountStatus()
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/settings.scss';
@import '@/assets/scss/modal.scss';

.settings-tab.misc {
  .tab-content {
    margin-top: 15px;
  }
  .alert.alert-warning a, .alert.alert-danger a {
    color: #dda3a2;
  }
}
</style>
