<template>
  <b-modal
    id="refresh-tink-connection-modal"
    centered
    ref="RefreshTinkConnectionModal"
    modal-class="spirecta-modal"
    @show="onShow"
    @hide="onHide"
  >
    <template slot="modal-title">
      {{ $t(translationPath + 'title', { name: this.connection ? this.connection.name : '' }) }}
    </template>
    <template v-if="connectionUpdated !== null">
      <b-embed id="refresh_iframe" ref="TinkRefreshIframe" type="iframe" :src="refreshLink" class="mt-5 tink-iframe" allow-scripts allow-same-origin allow-popups allow-forms allow-popups-to-escape-sandbox allow-top-navigation></b-embed>
    </template>
    <template v-else>
      <loader />
    </template>
    <template slot="modal-footer" class="text-center">
      <b-button variant="primary" @click="onClose">{{ $t('common.close') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'
import Tink from '@/mixins/Tink.js'
import { mapState } from 'vuex'

export default {
  name: 'RefreshTinkConnectionModal',
  components: { Loader },
  mixins: [Tink],
  props: {
    connection: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      translationPath: 'settings.your_data.tink.refresh_connection_modal.',
      connectionUpdated: null
    }
  },
  computed: {
    ...mapState('user', ['currentUser']),
    refreshLink () {
      if (this.connectionUpdated === null) {
        return ''
      }

      return `https://link.tink.com/1.0/credentials/refresh?client_id=${this.currentUser.tink_client_id}&redirect_uri=${document.location.origin}/tink-callback&authorization_code=${this.connectionUpdated.tink_credentials_authorization_code}&credentials_id=${this.connectionUpdated.credentials_id}&iframe=true`
    }
  },
  methods: {
    onShow () {
      this.$emit('show')
    },
    onHide () {
      this.$emit('hide')
    },
    show () {
      this.connectionUpdated = null
      this.$refs.RefreshTinkConnectionModal.show()
      this.$emit('show')

      if (this.connection !== null) {
        axios.put(`${process.env.VUE_APP_ROOT_API}/tink/refresh-tokens/${this.connection.id}`)
          .then(response => {
            this.connectionUpdated = response.data.data
          })
          .catch(err => {
            console.error(err)
          })
      }
    },
    hide () {
      this.$refs.RefreshTinkConnectionModal.hide()
      this.$emit('hide')
    },
    onClose () {
      this.$refs.RefreshTinkConnectionModal.hide()
      this.$emit('close')
    },
    receiveIframeMessage (event) {
      if (event.origin !== 'https://link.tink.com') {
        return false
      }

      const { type, error, additionalInformation } = JSON.parse(event.data)
      if (type !== 'error' && type !== 'none') {
        return false
      }

      if (error) {
        this.writeLog(
          'FAILED',
          'Refresh connection modal: ' + error.message,
          this.refreshLink,
          this.connection.tink_provider.provider_name,
          this.connection.tink_provider.id,
          this.connection.id
        )
        this.toastError(error.message, this.$t(this.translationPath + 'connect_to_provider_title'))
        return false
      }

      const credentialsId = Object.prototype.hasOwnProperty.call(additionalInformation, 'credentialsId') ? additionalInformation.credentialsId : null
      console.log(credentialsId)
      if (type === 'none' && credentialsId) {
        this.writeLog(
          'OK',
          'Refresh connection modal: ' + 'Receive credentials id: ' + credentialsId,
          this.refreshLink,
          this.connection.tink_provider ? this.connection.tink_provider.provider_name : '',
          this.connection.tink_provider ? this.connection.tink_provider.id : '',
          this.connection.id
        )
        this.saveCredentials(credentialsId, this.connection.id)
          .then(response => {
            this.writeLog(
              'OK',
              'Refresh connection modal: ' + 'Credentials ' + credentialsId + ' are saved to database.',
              this.refreshLink,
              this.connection.tink_provider ? this.connection.tink_provider.provider_name : '',
              this.connection.tink_provider ? this.connection.tink_provider.id : '',
              this.connection.id
            )
            this.$emit('refreshed', { connection: response.connection })
            this.$bvToast.toast(this.$t(this.translationPath + 'alert_refresh_success', { name: this.connection.name }), {
              title: this.$t(this.translationPath + 'alert_refresh_title'),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            })
          })
          .catch(err => {
            console.error(err)
            this.writeLog(
              'FAILED',
              'Refresh connection modal: ' + 'Credentials ' + credentialsId + ' are not saved to database.',
              this.refreshLink,
              this.connection.tink_provider.provider_name,
              this.connection.tink_provider.id,
              this.connection.id
            )
            this.$bvToast.toast(this.$t(this.translationPath + 'alert_refresh_fail', { name: this.connectionsUpdated.name }), {
              title: this.$t(this.translationPath + 'alert_refresh_title'),
              variant: 'danger',
              solid: true,
              'auto-hide-delay': 3000
            })
          })
        return true
      }

      return false
    }
  }
}
</script>

<style lang="scss">
#refresh-tink-connection-modal {
  .embed-responsive {
    height: 500px;
  }
  iframe {
    height: 92%;
  }
}
</style>
