import axios from 'axios'

export default {
  methods: {
    /**
     * Listen event on iframe associated with Tink. All Tink responses goes to this event.
     */
    $listenEvents () {
      window.addEventListener('message', this.receiveIframeMessage)
    },
    /**
     * Remove listeting events form Tink iframe. Use it always before destroying component with tink iframe.
     */
    $removeEvents () {
      window.removeEventListener('message', this.receiveIframeMessage)
    },
    /**
     * Send API request to save Tink credentials to database.
     * Credentials are received after autheticating on message event on iframe.
     * So use this function inside callback method for iframe message event. See callback mathod name in
     * $listenEvents method here.
     */
    async saveCredentials (credentialsId, connectionId) {
      return new Promise((resolve, reject) => {
        let r = {}
        const putData = { credentials_id: credentialsId }

        axios.put(`${process.env.VUE_APP_ROOT_API}/tink/save-credentials/${connectionId}`, putData)
          .then(response => {
            if (!response.data.data.is_logged) {
              r = { error: {}, meta: { isLogged: false } }
              this.$emit('save-credentials-fail', r)
              reject(r)
            } else {
              r = { connection: response.data.data.connection }
              this.$emit('save-credentials-success', r)
              resolve(r)
            }
          })
          .catch(err => {
            console.error(err)
            r = { error: err, meta: {} }
            this.$emit('save-credentials-fail', r)
            reject(r)
          })
      })
    },
    /**
     * Sends request to recreate all tokens and codes for connection.
     * Request returns connection instance with new codes.
     */
    async reinitializeConnection (iConnectionId) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/tink/cleared-connection/${iConnectionId}`)
          .then(response => {
            resolve(response.data.data)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    /**
     * Send request to API to write a log associated with using Tink Link (User interactions with Tink form).
     */
    async writeLog (status = 'Undefined', msg = 'No message', tinkLinkUrl = '', providerName = '', providerId = 0, connectionId = 0) {
      return new Promise((resolve, reject) => {
        const postData = {
          status: status,
          msg: msg,
          tink_link: tinkLinkUrl,
          provider_name: providerName,
          provider_id: providerId,
          connection_id: connectionId
        }
        axios.post(`${process.env.VUE_APP_ROOT_API}/tink/tink-link-log`, postData)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    }
  }
}
