<template>
  <b-modal
    id="test-tink-connection-modal"
    centered
    ref="TestTinkConnectionModal"
    modal-class="spirecta-modal"
    @show="onShow"
    @hide="onHide"
  >
    <template slot="modal-title">
      {{ $t(translationPath + 'title', { name: this.connection ? this.connection.name : '' }) }}
    </template>
    <template v-if="[CONNECTION_STATUS.TEST_SUCCESS, CONNECTION_STATUS.TEST_FAIL, CONNECTION_STATUS.ERROR, CONNECTION_STATUS.NOT_ACTIVE_OR_NOT_CONNECTED].indexOf(status) === -1">
      <loader />
    </template>
    <template v-else>
      <b-alert variant="success" :show="status === CONNECTION_STATUS.TEST_SUCCESS">{{ $t(translationPath + 'status_success') }}</b-alert>
      <b-alert variant="warning" :show="status === CONNECTION_STATUS.TEST_FAIL">{{ $t(translationPath + 'status_fail') }}</b-alert>
      <b-alert variant="warning" :show="status === CONNECTION_STATUS.NOT_ACTIVE_OR_NOT_CONNECTED">{{ $t(translationPath + 'status_not_active') }}</b-alert>
      <b-alert variant="danger" :show="status === CONNECTION_STATUS.ERROR">{{ $t(translationPath + 'status_error') }}</b-alert>
    </template>
    <template slot="modal-footer" class="text-center">
      <b-button variant="primary" @click="onClose">{{ $t('common.close') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'

export default {
  name: 'TestTinkConnectionModal',
  components: { Loader },
  props: {
    connection: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      translationPath: 'settings.your_data.tink.test_connection_modal.',
      status: 0,
      CONNECTION_STATUS: {
        NOT_CHECKED: 0,
        ACTIVE_CONNECTED: 1,
        NOT_ACTIVE_OR_NOT_CONNECTED: 2,
        TEST_SUCCESS: 3,
        TEST_FAIL: 4,
        ERROR: 5
      }
    }
  },
  methods: {
    onShow () {
      this.$emit('show')
    },
    onHide () {
      this.$emit('hide')
    },
    show () {
      this.status = this.CONNECTION_STATUS.NOT_CHECKED
      this.$refs.TestTinkConnectionModal.show()
      this.$emit('show')
      process.nextTick(() => {
        this.checkPermanentConnection()
          .then(checkStatus => {
            if (checkStatus === this.CONNECTION_STATUS.ACTIVE_CONNECTED) {
              this.retrieveTestData()
                .then(retrieveStatus => {
                  this.updateConnectionStatus()
                })
            }
          })
      })
    },
    hide () {
      this.$refs.TestTinkConnectionModal.hide()
      this.$emit('hide')
    },
    onClose () {
      this.$refs.TestTinkConnectionModal.hide()
      this.$emit('close')
    },
    async checkPermanentConnection () {
      return new Promise((resolve, reject) => {
        if (!this.connection) {
          this.status = this.CONNECTION_STATUS.ERROR
          reject(this.status)
        }

        axios.get(`${process.env.VUE_APP_ROOT_API}/tink/check-connection-established/${this.connection.id}`)
          .then(response => {
            if (response.data.data.connection.is_active && response.data.data.is_connected) {
              this.status = this.CONNECTION_STATUS.ACTIVE_CONNECTED
            } else {
              this.status = this.CONNECTION_STATUS.NOT_ACTIVE_OR_NOT_CONNECTED
            }
            resolve(this.status)
          })
          .catch(err => {
            console.error(err)
            this.status = this.CONNECTION_STATUS.ERROR
            reject(this.status)
          })
      })
    },
    async retrieveTestData () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/tink/accounts/${this.connection.id}`)
          .then(response => {
            if (response.data.data.accounts.length) {
              this.status = this.CONNECTION_STATUS.TEST_SUCCESS
            } else {
              this.status = this.CONNECTION_STATUS.TEST_FAIL
            }
            resolve(this.status)
          })
          .catch(err => {
            console.error(err)
            this.status = this.CONNECTION_STATUS.ERROR
            reject(this.status)
          })
      })
    },
    updateConnectionStatus () {
      if ([this.CONNECTION_STATUS.TEST_FAIL, this.CONNECTION_STATUS.NOT_ACTIVE_OR_NOT_CONNECTED].indexOf(this.status) !== -1) {
        const putData = {
          connection_ids: [this.connection.id],
          is_active: 1
        }
        axios.put(`${process.env.VUE_APP_ROOT_API}/tink/break-connections`, putData)
          .then(response => {
            console.log(response)
            this.$emit('connection-updated')
          })
          .catch(err => {
            console.error(err)
          })
      }
    }
  }
}
</script>
