<template>
  <b-modal
    id="rename-tink-connection-modal"
    centered
    ref="RenameTinkConnectionModal"
    modal-class="spirecta-modal"
    @show="onShow"
    @hide="onHide"
  >
    <template slot="modal-title">
      {{ $t(translationPath + 'title') }}
    </template>
    <p class="font-weight-lighter">{{ $t(translationPath + 'rename_intro', { currentName: this.connection !== null ? this.connection.name : '' }) }}</p>
    <b-form-group
      :invalid-feedback="invalidNameFeedback()"
    >
      <b-form-input
        ref="Name"
        id="name"
        v-model="name"
        :state="$v.name.$dirty ? !$v.name.$error : null"
      />
    </b-form-group>
    <template slot="modal-footer" class="text-center">
      <b-button variant="outline" @click="onCancel">{{ $t('common.cancel') }}</b-button>
      <b-button variant="primary" @click="onRename">{{ $t(translationPath + 'rename') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'RenameTinkConnectionModal',
  data () {
    return {
      translationPath: 'settings.your_data.tink.rename_connection_modal.',
      name: null
    }
  },
  props: {
    connection: {
      type: Object,
      default: null
    }
  },
  validations: {
    name: { required, minLength: minLength(2), maxLength: maxLength(255) }
  },
  methods: {
    onShow () {
      this.$emit('show')
    },
    onHide () {
      this.$emit('hide')
    },
    show () {
      this.$refs.RenameTinkConnectionModal.show()
      process.nextTick(() => {
        if (this.name === null && this.connection !== null) {
          this.name = this.connection.name
        }
        this.$refs.Name.focus()
        this.$emit('show')
      })
    },
    hide () {
      this.$refs.RenameTinkConnectionModal.hide()
      this.name = null
      this.$emit('hide')
    },
    onCancel () {
      this.$refs.RenameTinkConnectionModal.hide()
      this.name = null
      this.$v.name.$reset()
      this.$emit('cancel')
    },
    onRename () {
      this.$v.name.$touch()
      if (this.$v.name.$invalid) {
        return false
      }

      if (this.connection !== null) {
        this.$emit('rename', { id: this.connection.id, name: this.name })
        this.name = null
        this.$v.name.$reset()
      }
      this.$refs.RenameTinkConnectionModal.hide()
    },
    invalidNameFeedback () {
      if (this.$v.name.required === false) return this.$t(this.translationPath + 'errors.name_required')
      if (this.$v.name.minLength === false) return this.$t(this.translationPath + 'errors.name_minlength')
      if (this.$v.name.maxLength === false) return this.$t(this.translationPath + 'errors.name_maxlength')
      return ''
    }
  }
}
</script>
