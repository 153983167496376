<template>
  <div class="settings-submenu-wrapper">
    <b-row no-gutters>
      <b-col>
        <h1>{{ $t('settings.submenu.settings_title') }}</h1>
      </b-col>
    </b-row>
    <b-row class="submenu-container dark" no-gutters>
      <b-col md="auto">
        <b-link to="/settings/your-details" v-bind:class="{ disabled: isMenuYourDetailsActive }">
          {{ $t('settings.submenu.your_details') }}
        </b-link><span class="mx-3 divider">|</span>
      </b-col>
      <b-col md="auto">
        <b-link to="/settings/chart-of-account" v-bind:class="{ disabled: isMenuChartAccountActive }">
          {{ $t('settings.submenu.chart_of_accounts') }}
        </b-link><span class="mx-3 divider">|</span>
      </b-col>
      <b-col md="auto">
        <b-link to="/settings/subscription/prices" v-bind:class="{ disabled: isMenuSubscriptionActive }">
          {{ $t('settings.submenu.subscription') }}
        </b-link><span class="mx-3 divider">|</span>
      </b-col>
      <b-col md="auto">
        <b-link to="/settings/your-data/statistics" v-bind:class="{ disabled: isMenuYourDataActive }">
          {{ $t('settings.submenu.your-data') }}
        </b-link>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col no-gutters>
        <hr class="site-divider" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ['active'],
  name: 'SettingsSubmenu',
  data () {
    return {
      isMenuSubscriptionActive: this.$router.history.current.path.indexOf('/settings/subscription') === 0,
      isMenuYourDetailsActive: this.$router.history.current.path.indexOf('/settings/your-details') === 0,
      isMenuChartAccountActive: this.$router.history.current.path.indexOf('/settings/chart-of-account') === 0,
      isMenuHouseholdActive: this.$router.history.current.path.indexOf('/settings/household') === 0,
      isMenuPaymentsActive: this.$router.history.current.path.indexOf('/settings/payments') === 0,
      isMenuChangePasswordActive: this.$router.history.current.path.indexOf('/settings/change-password') === 0,
      isMenuYourDataActive: this.$router.history.current.path.indexOf('/settings/your-data') === 0,
      isMenuCardsActive: this.$router.history.current.path.indexOf('/settings/cards') === 0
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/submenu.scss';
</style>
