<template>
  <div>
    <h3 class="">{{ $t(translationPath + 'heading') }}</h3>
    <p class="col-lg-8 px-0">{{ $t(translationPath + 'description') }}</p>
    <ul>
      <li><b-link href="#" @click="download('accounts_simple')" class="text-blue">{{ $t(translationPath + 'download_accounts_simple') }} →</b-link></li>
      <li><b-link href="#" @click="download('accounts')" class="text-blue">{{ $t(translationPath + 'download_accounts') }} →</b-link></li>
      <li><b-link href="#" @click="download('account_groups')" class="text-blue">{{ $t(translationPath + 'download_account_groups') }} →</b-link></li>
      <li><b-link href="#" @click="download('transactions')" class="text-blue">{{ $t(translationPath + 'download_transactions') }} →</b-link></li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'MiscExport',
  data () {
    return {
      translationPath: 'settings.your_data.export.'
    }
  },
  methods: {
    download (type) {
      axios.get(`${process.env.VUE_APP_ROOT_API}/chart-of-accounts/export?type=${type}`)
        .then(response => {
          const oData = response.data.data
          let sData = ''

          let fields = []
          if (oData[type][0]) {
            fields = Object.keys(oData[type][0])
            sData = fields.join(';') + '\r\n'
          }

          for (const i in oData[type]) {
            for (const j in fields) {
              sData += (oData[type][i][fields[j]] !== undefined ? oData[type][i][fields[j]] : '') + ';'
            }
            sData += '\r\n'
          }

          const url = window.URL.createObjectURL(new Blob(['\ufeff', sData]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', type + '.csv')
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>
