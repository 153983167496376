<template>
  <b-row class="main-content-wrapper pt-0 your-data-tink" no-gutters>
    <div class="bordered-table w-100">
      <b-col>
        <h2>{{ $t(translationPath + 'title') }}</h2>
        <p class="text-regular">{{ $t(translationPath + 'description') }} <b-link href="https://forum.spirecta.se/t/anslutning-till-din-bank-eller-annan-leverantor-via-tink/896" target="_blank">{{$t('common.read_in_manual')}}</b-link></p>
      </b-col>
      <b-col class="px-0 py-0">
        <b-table
          class="spirecta-simple-table mb-0 tink-table" show-empty hover responsive striped
          :items="connections"
          :fields="fieldValues"
          :busy="isLoading"
          :empty-text="$t(translationPath + 'no_items')"
        >
          <template v-slot:table-busy>
            <loader/>
          </template>

          <template v-slot:cell(provider)="row">
            <span :class="{ 'text-inactive': !row.item.is_active }">{{ $t(row.item.tink_provider.translation_string_id) }}</span>
          </template>

          <template v-slot:cell(name)="row">
            <span :class="{ 'text-inactive': !row.item.is_active }">{{ $t(row.value) }}</span>
          </template>

          <template v-slot:cell(actions)="row">
            <template v-if="!row.item.is_active || !row.item.credentials_id">
              <b-link variant="primary" @click="activateConnection(row.item)" >{{ $t(translationPath + 'enable') }}</b-link> |
            </template>
            <template v-if="row.item.is_active">
              <b-link variant="primary" @click="onTestConnectionClick(row.item)" >{{ $t(translationPath + 'test') }}</b-link> |
            </template>
            <b-link @click="onRenameConnectionClick(row.item)">{{ $t(translationPath + 'rename_connection') }}</b-link> |
            <template v-if="row.item.is_active && row.item.credentials_id">
              <b-link @click="onRefreshConnectionClick(row.item)">{{ $t(translationPath + 'update_connection') }}</b-link> |
            </template>
            <template v-if="row.item.is_active">
              <b-link @click="breakConnection([row.item])">{{ $t(translationPath + 'disable') }}</b-link> |
            </template>
            <b-link @click="onDeleteConnectionsClick([row.item])">{{ $t('common.delete') }}</b-link>
          </template>

          <template v-slot:cell(comments)="row">
            <template v-if="row.item.is_active && row.item.credentials_id">
              <b-badge variant="success">{{ $t(translationPath + 'status_established_logged') }}</b-badge>
            </template>
            <template v-else-if="row.item.is_active && !row.item.credentials_id">
              <b-badge variant="warning">{{ $t(translationPath + 'status_established_not_logged') }}</b-badge>
            </template>
            <template v-else-if="!row.item.is_active">
              <b-badge variant="warning">{{ $t(translationPath + 'status_not_established') }}</b-badge>
            </template>
          </template>
        </b-table>
        <b-button v-if="!isLoading && activeConnections.length" class="btn btn-sm float-right mt-3" variant="danger" @click="breakConnection(activeConnections)">{{ $t(translationPath + 'disable_all') }}</b-button>
      </b-col>
    </div>

    <rename-tink-connection-modal
      ref="RenameTinkConnectionModal"
      :connection="connectionForRename"
      @cancel="onRenameConnectionCancelled"
      @rename="onRenameConnectionConfirmed"
    />

    <activate-tink-connection-modal
      ref="ActivateTinkConnectionModal"
      :connection="connectionToActivate"
      @close="onActivateConnectionClosed"
      @hide="onActivateConnectionClosed"
      @activated="onConnectionActivated"
    />

    <refresh-tink-connection-modal
      ref="RefreshTinkConnectionModal"
      :connection="connectionToRefresh"
      @close="onRefreshConnectionHide"
      @hide="onRefreshConnectionHide"
      @refreshed="onConnectionRefreshed"
    />

    <test-tink-connection-modal
      ref="TestTinkConnectionModal"
      :connection="connectionToTest"
      @close="onTestConnectionHide"
      @hide="onTestConnectionHide"
      @connection-updated="loadData()"
    />

    <delete-modal
      ref="DeleteModal"
      :heading="deleteModalHeading"
      :message="deleteModalMessage"
      @on-delete-cancel="onDeleteConnectionsCancel"
      @on-delete-confirm="onDeleteConnectionsConfirm"
    />
  </b-row>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'
import RenameTinkConnectionModal from '@/components/modals/RenameTinkConnectionModal'
import ActivateTinkConnectionModal from '@/components/modals/ActivateTinkConnectionModal'
import RefreshTinkConnectionModal from '@/components/modals/RefreshTinkConnectionModal'
import TestTinkConnectionModal from '@/components/modals/TestTinkConnectionModal'
import DeleteModal from '@/components/modals/DeleteModal'

export default {
  name: 'YourDataTink',
  components: {
    Loader,
    RenameTinkConnectionModal,
    ActivateTinkConnectionModal,
    RefreshTinkConnectionModal,
    TestTinkConnectionModal,
    DeleteModal
  },
  data () {
    return {
      translationPath: 'settings.your_data.tink.',
      connections: [],
      connectionForRename: null,
      connectionToActivate: null,
      connectionToRefresh: null,
      connectionToTest: null,
      connectionsToDelete: [],
      isLoading: true
    }
  },
  computed: {
    fieldValues () {
      return [
        { key: 'provider', label: this.$t('common.bank') },
        { key: 'name', label: this.$t('common.title') },
        { key: 'comments', label: this.$t('common.status'), class: 'td-status' },
        { key: 'credentials_refreshed_at', label: this.$t(this.translationPath + 'latest_update') },
        { key: 'actions', label: '' }
      ]
    },
    activeConnections () {
      return this.connections.filter(item => item.is_active)
    },
    inactiveConnections () {
      return this.connections.filter(item => !item.is_active)
    },
    deleteModalHeading () {
      if (this.connectionsToDelete.length === 1) {
        return this.$t(this.translationPath + 'delete_connection_heading', { name: this.connectionsToDelete[0].name })
      } else if (this.connectionsToDelete.length > 1) {
        return this.$t(this.translationPath + 'delete_connections_heading')
      } else {
        return this.$t('common.nothing_to_delete')
      }
    },
    deleteModalMessage () {
      if (this.connectionsToDelete.length === 1) {
        return this.$t(this.translationPath + 'delete_connection_text', { name: this.connectionsToDelete[0].name })
      } else if (this.connectionsToDelete.length > 1) {
        return this.$t(this.translationPath + 'delete_connections_text')
      } else {
        return this.$t('common.nothing_to_delete')
      }
    }
  },
  methods: {
    async loadData () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/tink/connections`)
          .then(response => {
            this.connections = response.data.data
            this.connections.map(item => {
              const tmp = item.name.match(/#.+#/)
              if (tmp !== null) {
                item.name = item.name.replace(/#.+#/, this.$t(tmp[0].replaceAll('#', '')))
              }
            })
          })
          .catch(err => {
            console.error(err)
          })
          .then(() => {
            this.isLoading = false
            resolve(true)
          })
      })
    },
    breakConnection (connectionsToDisable) {
      const putData = {
        connection_ids: []
      }

      const connectionIdsToDelete = []
      connectionsToDisable.map(connection => {
        putData.connection_ids.push(connection.id)
        connectionIdsToDelete.push(connection.id)
      })
      this.connections.map(connection => {
        if (connectionIdsToDelete.indexOf(connection.id) !== -1) {
          connection.credentials_id = null
          connection.is_active = 0
        }
      })

      if (putData.connection_ids.length) {
        axios.put(`${process.env.VUE_APP_ROOT_API}/tink/break-connections`, putData)
          .then((response) => {
            this.$bvToast.toast(this.$t(this.translationPath + 'alert_disable_success'), {
              title: this.$t(this.translationPath + 'alert_disable_title'),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            })
          })
          .catch(err => {
            console.error(err)
            this.$bvToast.toast(this.$t(this.translationPath + 'alert_disable_fail'), {
              title: this.$t(this.translationPath + 'alert_disable_title'),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            })
          })
      }
    },
    activateConnection (connection) {
      this.connectionToActivate = connection
      this.$refs.ActivateTinkConnectionModal.$listenEvents()
      this.$refs.ActivateTinkConnectionModal.show()
    },

    onActivateConnectionClosed () {
      this.connectionToActivate = null
      this.$refs.ActivateTinkConnectionModal.$removeEvents()
    },
    onRenameConnectionClick (item) {
      this.connectionForRename = item
      this.$refs.RenameTinkConnectionModal.show()
    },
    onRenameConnectionCancelled () {
      this.connectionForRename = null
    },
    onRenameConnectionConfirmed (data) {
      axios.put(`${process.env.VUE_APP_ROOT_API}/tink/save-connection/${data.id}`, { name: data.name })
        .then(response => {
          for (const i in this.connections) {
            if (this.connections[i].id === response.data.data.id) {
              this.connections[i].name = response.data.data.name
              break
            }
          }
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_rename_success'), {
            title: this.$t(this.translationPath + 'alert_rename_title'),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .catch(() => {
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_rename_fail'), {
            title: this.$t(this.translationPath + 'alert_rename_title'),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
    },
    onDeleteConnectionsClick (items) {
      this.connectionsToDelete = items
      this.$refs.DeleteModal.show()
    },
    onDeleteConnectionsCancel () {
      this.connectionsToDelete = []
    },
    onDeleteConnectionsConfirm () {
      const ids = []
      this.connectionsToDelete.map(c => {
        ids.push(c.id)
      })
      if (!ids.length) {
        return false
      }

      let sApiRoute = ''
      let oApiData = {}
      if (ids.length === 1) {
        sApiRoute = `${process.env.VUE_APP_ROOT_API}/tink/connections/${ids[0]}`
      } else if (ids.length > 1) {
        sApiRoute = `${process.env.VUE_APP_ROOT_API}/tink/connections`
        oApiData = { ids: ids }
      }

      const sToastHeading = ids.length === 1 ? 'alert_delete_title' : 'alert_delete_multiple_title'
      const sToastTextSuccess = ids.length === 1 ? 'alert_delete_success' : 'alert_delete_multiple_success'
      const sToastTextFail = ids.length === 1 ? 'alert_delete_fail' : 'alert_delete_multiple_fail'

      axios.delete(sApiRoute, { data: oApiData })
        .then(response => {
          this.connections = this.connections.filter(c => ids.indexOf(c.id) === -1)
          this.$bvToast.toast(this.$t(this.translationPath + sToastTextSuccess, { name: this.connectionsToDelete[0].name }), {
            title: this.$t(this.translationPath + sToastHeading),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + sToastTextFail, { name: this.connectionsToDelete[0].name }), {
            title: this.$t(this.translationPath + sToastHeading),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
    },
    onRefreshConnectionClick (item) {
      this.connectionToRefresh = item
      this.$refs.RefreshTinkConnectionModal.$listenEvents()
      process.nextTick(() => {
        this.$refs.RefreshTinkConnectionModal.show()
      })
    },
    onRefreshConnectionHide () {
      this.connectionToRefresh = null
      this.$refs.RefreshTinkConnectionModal.$removeEvents()
    },
    onConnectionRefreshed () {
      this.loadData()
      this.$refs.RefreshTinkConnectionModal.hide()
      this.connectionToRefresh = null
    },
    onConnectionActivated () {
      this.loadData()
      this.$refs.ActivateTinkConnectionModal.hide()
      this.connectionToActivate = null
    },
    onTestConnectionClick (item) {
      this.connectionToTest = item
      this.$refs.TestTinkConnectionModal.show()
    },
    onTestConnectionHide () {
      this.connectionToTest = null
    }
  },
  created () {
    this.loadData()
  }
}
</script>

<style lang="scss">
.your-data-tink {
  .tink-table {
    font-size: 90%;
    .td-status {
      .badge {
        white-space: normal;
      }
    }
  }
  .text-inactive {
    color: grey;
    font-weight: 400;
    font-size: 90%;
  }
}
</style>
